<template>
  <div
    class="group/product-card relative transform-gpu overflow-hidden bg-white transition hover:z-10"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="relative mb-2.5">
      <div
        class="aspect-h-1 aspect-w-1 w-full overflow-hidden bg-white"
        :style="{
          filter: `brightness(${imageBrightnessFilter}%)`,
          backgroundColor: imageBackgroundColor?.hexa || 'transparent',
        }"
      >
        <CommonProductCardImages
          class="block h-full w-full"
          :to="productLink"
          :is-hover="isHover"
          :images="productImages"
          :product="product"
          @click="setGaSelectItemEvent"
        />
      </div>
      <div
        class="pointer-events-none absolute left-4 top-3.5 flex max-h-full flex-col flex-wrap gap-2"
      >
        <UiChip
          v-if="product.sale"
          color="secondary"
          :text="$t('sale')"
          class="h-6 py-0 font-light"
        />
        <UiChip
          v-if="product.bestseller"
          color="secondary"
          class="h-6 py-0 font-light"
          :text="$t('bestSeller')"
        />
        <template v-if="product.badges">
          <UiChip
            v-for="(badge, idx) in product.badges"
            :key="idx"
            color="secondary"
            class="h-6 py-0 font-light"
            :text="badge.title"
          />
        </template>
      </div>
      <UiButton
        v-if="product.default_sku"
        class="absolute right-2 top-2 rounded-full bg-white text-gray-90"
        variant="clear"
        variant-type="icon"
        size="xs"
        text="xs"
        :aria-label="$t('addToWishlist')"
        @click="
          updateWishlist({
            sku: product.default_sku,
            images: [
              product.variant_plp_image?.image?.url ||
                product.images?.[0]?.url ||
                '',
            ],
            cost: product.sale && discountValue ? discountValue : productPrice,
            slug: product.slug,
            configuration_id: product.default_sku,
            engraving: {},
            materials: product.materials,
            material_categories: product.material_categories,
            material_categories_plp_hover_ids:
              product.material_categories_plp_hover_ids,
            translations: product.translations as ProductData['translations'],
            active_configurations_count: product.active_configurations_count,
            min_cost: product.min_cost,
            product_id: (product as CartItem).product_id || product.id,
            collection_ids: product.collections_ids || [],
            vendor_model: product.vendor_model || '',
          } as WishListData);
          setGaSelectItemEvent();
        "
      >
        <UiIcon
          name="heart"
          class="size-3.5"
          :class="[
            inWishlist({
              sku: product.default_sku,
              engraving: {},
            } as WishListData)
              ? 'fill-gray-90'
              : '',
          ]"
          aria-hidden="true"
        />
      </UiButton>
    </div>

    <div
      class="relative flex flex-col space-y-1"
      :style="{
        alignItems: cardContentPosition === 'left' ? 'start' : 'center',
      }"
    >
      <div class="flex">
        <NuxtLink
          :to="productLink"
          class="block flex-auto text-xs font-light md:text-sm"
          @click="setGaSelectItemEvent"
        >
          <span>{{ productTitlePlp }}</span>
        </NuxtLink>
      </div>

      <p
        class="flex items-center gap-1 font-gt-ultra-median text-xxs font-250 italic text-gray-60"
      >
        <!--        <span v-if="product.sale" class="text-error">-->
        <!--          <b class="font-medium">{{ $n(discountValue, "currency") }}</b>-->
        <!--          ({{ product.sale_value }}% OFF)-->
        <!--        </span>-->
        <!--        <span :class="[product.sale ? 'line-through' : '']">-->
        <!--          {{ $n(productPrice, "currency") }}-->
        <!--        </span>-->
        <template v-if="product?.active_configurations_count">
          <template v-if="product.min_cost">
            <span class="lowercase">{{ $t("startingAt") }}:</span>
            <span class="text-base font-normal not-italic text-orange-60">
              {{ $n(product.min_cost, "currency") }}
            </span>
          </template>
          <span v-else class="lowercase">
            {{ $t("outOfStock") }}
          </span>
        </template>
        <span
          v-else-if="productPrice"
          class="text-base font-normal not-italic text-orange-60"
        >
          {{ $n(productPrice, "currency") }}
        </span>
        <span
          v-else
          class="font-gt-ultra-median text-xxs font-250 lowercase italic text-gray-60"
        >
          {{ $t("outOfStock") }}
        </span>
      </p>

      <template v-for="block in productMaterials" :key="block?.id">
        <SharedProductMaterials
          v-if="block?.materials?.length >= 1"
          :product="product"
          :data="block"
          :show-images="props.plpType === plpTypes.default"
          :show-current-material="false"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CatalogData } from "~~/types/api/catalog.types";
import { getProductImages } from "~/utils";
import { plpTypes } from "@/configs";
import { ProductData } from "~/types/api/product.types";
import type { WishListData } from "~/composables/_useWishlist";
import { CartItem } from "~/types/api/cart.types";
import { ColorPickerData } from "~/types/api/block.types";

const props = withDefaults(
  defineProps<{
    hover?: boolean;
    imageBrightnessFilter?: string;
    cardContentPosition?: string;
    product: CatalogData;
    plpType?: string;
    extractSetting?: boolean;
    productIndex: number;
    statisticItemListName?: string;
    imageBackgroundColor?: ColorPickerData;
  }>(),
  {
    imageBrightnessFilter: "100",
    cardContentPosition: "left",
    productIndex: 0,
    statisticItemListName: "",
  },
);

const { inWishlist, updateWishlist } = useWishlist();
const {
  productMaterials,
  productLink,
  productPrice,
  productTitlePlp,
  discountValue,
} = useProduct(props.product, props.plpType);

const { mdAndDown } = useAppBreakpoints();
// const { openSystemDialog } = useSystemDialogs();
// const { t } = useI18n();
//
// const { addToCart } = useCart();
//
const { gaSelectItemEvent } = useGoogleAnalyticsEvents();
//
// const { fbAddToCartEvent } = useFacebookPixelEvents();
//
// const pending = ref(false);
const isHover = ref<boolean>(false);

const productImages = computed(() => {
  return getProductImages(props.product);
});

// async function onAddToCart(product: CatalogData) {
//   pending.value = true;
//   try {
//     let imageIdListIds = [] as number[];
//     let image = null;
//     if (!productImages.value[0].id && !isEmpty(productImages.value)) {
//       image = await mergeImages([{ src: productImages.value[0]?.url }]);
//     } else {
//       imageIdListIds = [productImages.value[0].id];
//     }
//
//     await addToCart({
//       configuration_id: productSKU.value,
//       vendor_model: product.vendor_model,
//       images: image ? [image] : [],
//       images_ids: imageIdListIds,
//     });
//     gaAddToCartEvent(product, product?.default_config);
//     fbAddToCartEvent(product, product?.default_config);
//     setGaSelectItemEvent();
//   } catch (error) {
//     if (error instanceof ImageMergeError) {
//       openSystemDialog({
//         type: "alert",
//         title: `${t("error")}!`,
//         text: `${t("failedLoadImage")}!`,
//       });
//     }
//   } finally {
//     pending.value = false;
//   }
// }

function onMouseEnter() {
  if (mdAndDown) return;
  isHover.value = true;
}

function onMouseLeave() {
  isHover.value = false;
}

function setGaSelectItemEvent() {
  gaSelectItemEvent(props.product, {
    index: props.productIndex,
    item_list_name: props.statisticItemListName,
  });
}
</script>
